import { Box, IconButton, Stack, Typography } from "@mui/material";
// import { FiShoppingCart, FiUser } from "react-icons/fi";

import { ProductType } from "types";
import { Img } from "./Img";
import {
  CIRCLE_CROWN_ICON,
  CIRCLE_VERIFIED_ICON,
  CircleChatIcon,
  LikeIcon,
  RightArrowIcon,
} from "Assets/icons";
import { formatImgUrl } from "utils/helpers";
import { MouseEvent, ReactNode, forwardRef, useMemo } from "react";
import Row from "./Row";
import {
  DIRECT_PATHS,
  SEARCH_PARAM_VALUES,
  SEARCH_PARAMS,
} from "constants/routes";
import CountryFlag from "./CountryFlag";
import { formatCurrencyWithUnit } from "helpers/string";
import { useIntl } from "react-intl";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { SendInquiryButton } from "./Button";
import {
  LgTextSemiBold,
  Link,
  StyledA,
  StyledRouterA,
  XsText,
  XsTextMd,
} from "./Text";
import useChat from "hooks/useChat";
import { StyledProduct } from "./Product.styles";
import { getCountryFlag } from "helpers/address";
import TimeDiff from "./TimeDiff";
import dayjs from "dayjs";
import { objectToQueryString } from "helpers/search";
import { RunningText } from "./AnimationText";
import { COLORS } from "constants/layout";
import { useParams } from "react-router-dom";
import { ParamsType } from "types/params";

type Props = {
  product?: ProductType;
  swiper?: boolean;
  hideCompanyInfo?: boolean;
  isNew?: boolean;
};

const Product = forwardRef(
  (
    { product, swiper = true, hideCompanyInfo = false, isNew = false }: Props,
    ref: any
  ) => {
    const t = useIntl();
    const now = dayjs();
    const { categoryId } = useParams<ParamsType>();
    const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();
    const {
      price_min = 0,
      price_max = 0,
      moq_unit = "",
      moq,
      farm_id = "",
      fd_country,
      country_code,
      is_featured,
      farm_item_id,
      identity,
      price_status,
      last_updated,
      short_summary,
      post_image_url,
      country_path = "",
      country,
    } = product || {};
    const { onAddChatToShowList } = useChat();

    const isVerified = useMemo(
      () => product?.verified === 1,
      [product?.verified]
    );

    const onClickFarmName = (e: MouseEvent<HTMLSpanElement>) => {
      e?.stopPropagation();
      // history.push(DIRECT_PATHS.companyDetail(farm_id));
    };

    const onClickChat = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (identity) {
        onAddChatToShowList(identity);
      }
    };

    const postTabLink = useMemo(
      () =>
        DIRECT_PATHS.itemDetail(farm_item_id) +
        "?" +
        objectToQueryString({
          [SEARCH_PARAMS.tab]: SEARCH_PARAM_VALUES.tabPost,
        }),
      [farm_item_id]
    );

    const priceStatusConfigs = useMemo(() => {
      switch (price_status) {
        case "COMPETITIVE":
          return { label: "good_price" };

        default:
          return {};
      }
    }, [price_status]);

    return (
      <Link to={`/${product?.slug || ""}`}>
        <StyledProduct swiper={swiper} ref={ref} className="product">
          <Box className="image">
            <Img
              src={formatImgUrl(product?.farm_item_image, 3)}
              alt="img"
              height="100%"
              width="100%"
            />
          </Box>
          <Box className="info_box">
            <StyledA>
              <Typography className="name cutoff-text twoline" component="h2">
                {isNew && <span>{t.formatMessage({ id: "new" })}</span>}
                {product?.farm_item_name}
              </Typography>
            </StyledA>
            <div>
              <LgTextSemiBold className="price ellipsis-text">
                {price_min <= 0 && t.formatMessage({ id: "to_be_negotiated" })}
                {price_min > 0 &&
                  `${formatCurrencyWithUnit(price_min)} ${
                    price_min < price_max
                      ? ` - ${formatCurrencyWithUnit(price_max)}`
                      : ""
                  }`}
              </LgTextSemiBold>
              <XsTextMd className="price-status">
                {priceStatusConfigs.label && (
                  <>
                    <LikeIcon />
                    {t.formatMessage({ id: priceStatusConfigs.label })}
                  </>
                )}
              </XsTextMd>
            </div>
            <Typography className="min_order_qlt ellipsis-text">
              {moq && (
                <>
                  {t.formatMessage({ id: "min_order" })}:{" "}
                  <span>
                    {moq} {moq_unit}
                  </span>
                </>
              )}
            </Typography>
            {!hideCompanyInfo && (
              <Stack spacing={0.5}>
                <StyledRouterA to={DIRECT_PATHS.supplierDetail(farm_id)}>
                  <XsTextMd color={COLORS.gray[800]} className="ellipsis-text">
                    {product?.farm_name}
                  </XsTextMd>
                </StyledRouterA>
                <Row spacing={1} className="company_info">
                  {country_code && (
                    <Img src={getCountryFlag(country_code).src} alt="flag" />
                  )}
                  <StyledA
                    href={
                      categoryId
                        ? `${DIRECT_PATHS.category(categoryId)}/${country_path}`
                        : ""
                    }
                  >
                    <XsText
                      className="cutoff-text oneline"
                      onClick={onClickFarmName}
                      component="h3"
                    >
                      {country}
                    </XsText>
                  </StyledA>
                </Row>
              </Stack>
            )}
          </Box>

          {/* sticky status box */}
          <Stack className="stick" spacing={0.5} alignItems="flex-end">
            {isVerified && (
              <XsTextMd className="status-label green-label verified-icon">
                <Img src={CIRCLE_VERIFIED_ICON} alt="icon" />
                {t.formatMessage({ id: "verified" })}
              </XsTextMd>
            )}
            {fd_country && <CountryFlag {...{ fd_country, country_code }} />}
            {is_featured === 1 && (
              <XsTextMd className="sponsored status-label yellow-label">
                <Img src={CIRCLE_CROWN_ICON} alt="icon" />
                {!isMobile && t.formatMessage({ id: "sponsored" })}
              </XsTextMd>
            )}
          </Stack>

          {/* action box */}
          <Row spacing={{ xs: 0.75, md: 2 }} className="hover-box">
            <SendInquiryButton
              className="send-inq-btn"
              data={{ farm_id, farm_item_id }}
            />
            <IconButton className="chat-icon" onClick={onClickChat}>
              <CircleChatIcon />
            </IconButton>
          </Row>

          {!short_summary && last_updated && (
            <Typography className="last-updated min_order_qlt ellipsis-text">
              {t.formatMessage(
                { id: "last_updated_value" },
                {
                  s: (code: ReactNode) => <span>{code}</span>,
                  value: (
                    <TimeDiff
                      startTime={dayjs(last_updated).format()}
                      endTime={now.format()}
                    />
                  ),
                }
              )}
            </Typography>
          )}
          {short_summary && (
            <Link to={postTabLink}>
              <Row className="post-box" p={0.5} spacing={0.5}>
                {post_image_url && (
                  <Img
                    src={formatImgUrl(post_image_url, 1)}
                    alt="img"
                    className="object-cover post-img"
                  />
                )}
                <Stack
                  flex={1}
                  maxWidth={`calc(100% - ${post_image_url ? "50" : "24"}px)`}
                >
                  <StyledRouterA to={postTabLink}>
                    <RunningText className="post-title">
                      {short_summary}
                    </RunningText>
                  </StyledRouterA>
                  <Typography className="post-time">
                    <TimeDiff
                      startTime={dayjs(last_updated).format()}
                      endTime={now.format()}
                    />
                    {t.formatMessage({ id: "ago" })}
                  </Typography>
                </Stack>
                <div className="post-svg">
                  <RightArrowIcon />
                </div>
              </Row>
            </Link>
          )}
        </StyledProduct>
      </Link>
    );
  }
);

export default Product;
