import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const utcPlus7Timezone = "Asia/Bangkok";

export const formatDatetime = (
  datetime: string,
  format: string = "DD/MM/YYYY"
) => {
  return dayjs
    .tz(datetime, utcPlus7Timezone)
    .tz(dayjs.tz.guess())
    .format(format);
};

export const formatDate = (date: string) => {
  return date && date.split("-").reverse().join("/");
};

export const dateDiffWithTimestamp = (str1: any, str2: any) => {
  var second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7;
  let date1 = new Date(str1);
  let date2 = new Date(str2);
  var diff = date2.getTime() - date1.getTime();
  return isNaN(diff)
    ? NaN
    : {
        diff: diff,
        second: Math.floor(diff / second),
        minute: Math.floor(diff / minute),
        hour: Math.floor(diff / hour),
        day: Math.floor(diff / day),
        week: Math.floor(diff / week),
        month:
          date2.getFullYear() * 12 +
          date2.getMonth() -
          (date1.getFullYear() * 12 + date1.getMonth()),
      };
};

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const dateDiff = (str1: string, str2: string) => {
  let date1 = dayjs(str1);
  let date2 = dayjs(str2);
  // let date1 = dayjs.tz(str1, utcPlus7Timezone).tz(browserTimezone);
  // let date2 = dayjs.tz(str2, utcPlus7Timezone).tz(browserTimezone);

  // console.log(date1, date2);

  let monthAgo = date2.diff(date1, "month");
  let weekAgo = monthAgo > 0 ? 0 : date2.diff(date1, "week");
  let weekBonusAgo = monthAgo > 0 ? 0 : weekAgo % 7;
  let dayAgo = weekAgo > 0 ? 0 : date2.diff(date1, "day");
  let dayBonusAgo = weekBonusAgo > 0 || monthAgo > 0 ? 0 : dayAgo % 30;
  let hourAgo = date2.diff(date1, "hour");
  let hourBonusAgo = weekBonusAgo > 0 || monthAgo > 0 ? 0 : hourAgo % 24;
  let minuteAgo = date2.diff(date1, "minute");
  let minuteBonusAgo =
    weekBonusAgo > 0 || monthAgo > 0 || dayAgo > 0 ? 0 : minuteAgo % 60;

  return {
    month: monthAgo,
    week: weekBonusAgo,
    day: dayBonusAgo,
    hour: hourBonusAgo,
    minute: minuteBonusAgo,
  };
};

export function getDatesBetween(startDate?: Date, endDate?: Date) {
  const dates = [];
  if (startDate && endDate) {
    let currentDate = dayjs(startDate);
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

    while (currentDate.isBefore(formattedEndDate)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }
  }

  return dates;
}

export function formatShortDate(inputDate: Date) {
  return dayjs(inputDate).format("MMM DD");
}

export const formatChatDateTime = (
  dateTime: Date | string,
  format: { same: string; other: string } = {
    same: "h:mm A",
    other: "h:mm A DD/MM/YYYY",
  }
): string => {
  const date = dayjs(dateTime);
  const today = dayjs();
  if (date.isSame(today, "day")) {
    return date.format(format.same);
  } else {
    return date.format(format.other);
  }
};
