import { Box, styled } from "@mui/material";

export const StyledProduct = styled(Box)<{ swiper: boolean }>(
  ({ theme, swiper }) => ({
    borderRadius: 8,
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
    height: 436,
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #eaecf0",
    "& .image": {
      height: 195,
      overflow: "hidden",
      img: {
        transform: "scale(1.5)",
        width: "100%",
        height: "100%",
        objectFit: "scale-down",
      },
    },
    "& .info_box": {
      padding: 8,
      paddingBottom: 4,
      "& .name": {
        fontWeight: 500,
        fontSize: 12,
        minHeight: 36,
        "&:hover": {
          span: {
            textDecoration: "underline",
            textDecorationColor: "var(--error-500)",
          },
        },
        span: {
          color: "var(--error-500)",
          fontStyle: "italic",
          paddingRight: 6,
          fontWeight: 600,
        },
      },
      "& .price": {
        color: "var(--green-500)",
        marginTop: 4,
        "& span": {
          marginLeft: 16,
          textDecoration: "line-through",
          fontWeight: 100,
          color: "#828282",
          fontSize: 12,
        },
      },
    },
    ".stick": {
      position: "absolute",
      right: 8,
      top: 8,
      fontWeight: 500,
    },
    ".sponsored, .verified-icon": {
      display: "flex",
      gap: 4,
      alignItems: "center",
      padding: "2px 6px",
    },
    ".price-status": {
      fontSize: 10,
      height: 14,
      display: "flex",
      alignItems: "center",
      gap: 4,
      color: "var(--error-500)",
      svg: {
        width: 12,
        height: 11,
      },
    },
    ".verified_icon": {
      height: 26,
      width: "fit-content",
      padding: "4px 8px",
      backgroundColor: "#fff",
      borderRadius: 8,
    },
    "& .country_flag": {
      position: "absolute",
      top: 42,
      right: 8,
    },
    ".min_order_qlt": {
      fontSize: 10,
      color: "#808089",
      margin: "4px 0",
      minHeight: 12,
      span: {
        fontWeight: 500,
        color: "var(--gray-800)",
      },
    },
    ".company_info": {
      img: {
        width: 16,
        height: 16,
        objectFit: "scale-down",
      },
      p: {
        color: "#1D2939",
      },
    },
    ".action_box": {
      button: {
        fontSize: 10,
        textTransform: "initial",
        fontWeight: 600,
        borderRadius: 8,
        svg: {
          width: 16,
          height: 16,
        },
      },
      ".order_btn": {
        color: "var(--color)",
      },
      ".contact_btn": {
        color: "#fff",
        flex: 1,
        backgroundColor: "var(--sub-color)",
      },
    },
    ".post-box": {
      margin: "4px 10px",
      borderRadius: 4,
      border: "0.5px solid var(--gray-300)",
      cursor: "pointer",
      img: {
        width: 24,
        height: 24,
      },
      ".post-title": {
        fontSize: 10,
        color: "var(--gray-800)",
      },
      ".post-time": {
        fontSize: 8,
        color: "var(--gray-600)",
        fontStyle: "italic",
      },
      ".post-svg": {
        padding: "0 6px",
        cursor: "pointer",
      },
    },
    ".hover-box": {
      padding: "0 8px",
      ".send-inq-btn": {
        fontSize: "calc(100% * 0.85)",
        padding: 6,
      },
      ".chat-icon": {
        padding: 0,
        "&:hover": {
          backgroundColor: "var(--green-50)",
        },
      },
    },
    ":hover": {
      ".hover-box": {
        display: "flex",
      },
    },
    ".last-updated": {
      padding: "0 10px",
    },
    [theme.breakpoints.down("md")]: {
      height: swiper ? 354 : "100%",
      "& .image": {
        height: swiper ? 116 : 184,
      },
      ".hover-box": {
        ".send-inq-btn": {
          fontSize: 14,
          padding: 4,
        },
      },
      ".price-status": {
        fontSize: 8,
        svg: {
          width: 10,
          height: 9,
        },
      },
      ".chat-icon": {
        height: 32,
      },
      "& .price": {
        fontSize: 16,
      },
    },

    [theme.breakpoints.down("sm")]: {},
  })
);
