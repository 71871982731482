import { BoxProps } from "@mui/material";
import { Img, PreloadImgs } from "./Img";
import { memo, ReactNode, useMemo } from "react";
import { useLanguageContext } from "./LanguageProvider";
import {
  BUY_PLAN_BANNER_EN_DESKTOP_IMG,
  BUY_PLAN_BANNER_EN_MOBILE_IMG,
  BUY_PLAN_BANNER_VN_DESKTOP_IMG,
  BUY_PLAN_BANNER_VN_MOBILE_IMG,
  REGISTER_BANNER_EN_DESKTOP_IMG,
  REGISTER_BANNER_EN_MOBILE_IMG,
  REGISTER_BANNER_VN_DESKTOP_IMG,
  REGISTER_BANNER_VN_MOBILE_IMG,
} from "Assets/images";
import { COUNTRY_CODES } from "constants/data";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { LgTextMd, OpenTabLink, StyledATag, XlTextMd } from "./Text";
import { objectToQueryString } from "helpers/search";
import { PATHS, SEARCH_PARAM_VALUES, SEARCH_PARAMS } from "constants/routes";
import {
  StyledInviteRegisterBanner,
  StyledTryTrialPlanBanner,
  StyledUpgradePlanToConnectBuyerTips,
} from "./TipBanners.styles";
import { useIntl } from "react-intl";
import Row from "./Row";
import { CIRCLE_CROWN_ICON } from "Assets/icons";
import { YellowButton } from "./Button";
import useUser from "hooks/useUser";

export const InviteRegisterBanner = (props: BoxProps) => {
  const { language } = useLanguageContext();
  const { isMobile } = useMediaBreakpoints();

  const currentBanner = useMemo(() => {
    switch (language) {
      case COUNTRY_CODES.vi:
        return isMobile
          ? REGISTER_BANNER_VN_MOBILE_IMG
          : REGISTER_BANNER_VN_DESKTOP_IMG;
      default:
        return isMobile
          ? REGISTER_BANNER_EN_MOBILE_IMG
          : REGISTER_BANNER_EN_DESKTOP_IMG;
    }
  }, [language, isMobile]);

  return (
    <StyledATag
      href={
        PATHS.register +
        "?" +
        objectToQueryString({
          [SEARCH_PARAMS.accountType]: SEARCH_PARAM_VALUES.accountTypeBiz,
        })
      }
    >
      <StyledInviteRegisterBanner {...props}>
        <img src={currentBanner} alt="img" />
      </StyledInviteRegisterBanner>
    </StyledATag>
  );
};

export const SuggestBuyPlanBanner = (props: BoxProps) => {
  const { language } = useLanguageContext();
  const { isMobile } = useMediaBreakpoints();

  const currentBanner = useMemo(() => {
    switch (language) {
      case COUNTRY_CODES.vi:
        return isMobile
          ? BUY_PLAN_BANNER_VN_MOBILE_IMG
          : BUY_PLAN_BANNER_VN_DESKTOP_IMG;
      default:
        return isMobile
          ? BUY_PLAN_BANNER_EN_MOBILE_IMG
          : BUY_PLAN_BANNER_EN_DESKTOP_IMG;
    }
  }, [language, isMobile]);

  return (
    <OpenTabLink href={PATHS.supplierPricing}>
      <StyledInviteRegisterBanner {...props}>
        <img src={currentBanner} alt="img" />
      </StyledInviteRegisterBanner>
    </OpenTabLink>
  );
};

export const UpgradePlanToConnectBuyerTips = memo(() => {
  const t = useIntl();
  return (
    <StyledUpgradePlanToConnectBuyerTips>
      <Row
        className="content"
        spacing={1}
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
      >
        <Row spacing={1} justifyContent="center">
          <div className="icon-box">
            <Img src={CIRCLE_CROWN_ICON} alt="icon" />
          </div>
          <LgTextMd className="text" textAlign="center">
            {t.formatMessage(
              {
                id: "boost_your_product_visibility_and_reach_more_buyers_upgrade_to_premium_membership_now",
              },
              {
                s: (code: ReactNode) => <span>{code}</span>,
                product: <span className="number">80</span>,
              }
            )}
          </LgTextMd>
        </Row>
        <StyledATag href={PATHS.supplierPricing} target="_blank">
          <YellowButton>{t.formatMessage({ id: "upgrade_now" })}</YellowButton>
        </StyledATag>
      </Row>
    </StyledUpgradePlanToConnectBuyerTips>
  );
});

export const TryTrialPlanBanner = () => {
  const t = useIntl();
  return (
    <StyledTryTrialPlanBanner
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <XlTextMd color="var(--yellow-500)" className="title">
        {t.formatMessage({ id: "special_promotions_oct" })}
      </XlTextMd>
      <OpenTabLink href={PATHS.supplierPricing}>
        <YellowButton>{t.formatMessage({ id: "special_promotions_oct_btn" })}</YellowButton>
      </OpenTabLink>
    </StyledTryTrialPlanBanner>
  );
};

// InviteRegisterBanner
// UpgradePlanToConnectBuyerTips
export const FirstDynamicTipBanner = () => {
  const { isLoggedIn, isSupplier } = useUser();
  return (
    <>
      <PreloadImgs
        images={
          !isLoggedIn
            ? [
                REGISTER_BANNER_EN_DESKTOP_IMG,
                REGISTER_BANNER_EN_MOBILE_IMG,
                REGISTER_BANNER_VN_DESKTOP_IMG,
                REGISTER_BANNER_VN_MOBILE_IMG,
              ]
            : []
        }
      />
      {!isLoggedIn && <InviteRegisterBanner />}
      {isLoggedIn && isSupplier && <UpgradePlanToConnectBuyerTips />}
    </>
  );
};
