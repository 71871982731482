import { createRef, useMemo } from "react";
import {
  Typography,
  Grid,
  Divider,
  Container,
  Stack,
  Link,
} from "@mui/material";

import { StyledFooter, StyledLastFooter } from "./styles";
import LogoFreshdi from "Components/LogoFreshdi";
import IntlMsg from "Components/IntlMsg";
import Row from "Components/Row";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { PATHS } from "../../constants/routes";
import { StyledA } from "Components/Text";
import { useIntl } from "react-intl";

const Footer = ({ forVerify = false, att = {} }) => {
  const header = createRef();
  const { downTabletBreakpoint } = useMediaBreakpoints();
  const footercolumns = [
    {
      label: <IntlMsg id="GetSupport" />,
      children: [
        {
          label: <IntlMsg id="FAQs" />,
          href: PATHS.buyerFAQs,
        },
        {
          label: <IntlMsg id="ContactUs" />,
          href: PATHS.contactUs,
        },
        {
          label: <IntlMsg id="ReportAbuse" />,
          href: null,
        },
      ],
    },
    {
      label: <IntlMsg id="FeaturedServices" />,
      children: [
        {
          label: <IntlMsg id="SmartTrace" />,
          href: PATHS.smartTrace,
        },
      ],
    },
    {
      label: <IntlMsg id="SellOnFreshdi" />,
      children: [
        {
          label: <IntlMsg id="request_for_quotations" />,
          href: PATHS.rfqMarket,
        },
        {
          label: <IntlMsg id="SellerCentral" />,
          href: PATHS.supplierCenter,
        },
        {
          label: <IntlMsg id="membership_program" />,
          href: PATHS.supplierPricing,
        },
      ],
    },
    {
      label: <IntlMsg id="SourceOnFreshdi" />,
      children: [
        {
          label: <IntlMsg id="QuickPostRFQ" />,
          href: PATHS.quickRfq,
        },
        {
          label: <IntlMsg id="BuyerCentral" />,
          href: PATHS.buyerCenter,
        },
        {
          label: <IntlMsg id="MembershipProgram" />,
          href: PATHS.buyerPricing,
        },
      ],
    },
    ...(!downTabletBreakpoint
      ? [
          {
            component: FirstColumn,
          },
        ]
      : []),
  ];

  return (
    <>
      <Divider />
      <StyledFooter py={4} sx={{ ...att }}>
        <Container maxWidth="xl">
          <Grid container spacing={4} mb={3} className="footer_main">
            {footercolumns?.map((data, index) => {
              let Component = data?.component;

              return (
                <Grid item xs={12} sm={6} md={2.4} key={index}>
                  <Stack spacing={2}>
                    <Typography variant="body1" className="footer_title">
                      {data?.label}
                    </Typography>
                    {Component && <Component />}
                    <ChildrenList children={data?.children} />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
          <Divider />
          <LastFooter header={header} forVerify={forVerify} att={{ mt: 2 }} />
        </Container>
      </StyledFooter>
    </>
  );
};

const FirstColumn = () => {
  return (
    <Stack spacing={2}>
      <LogoFreshdi />
      <Typography pt={2}>contact@freshdi.com</Typography>
    </Stack>
  );
};

const ChildrenList = ({ children = [] }) => {
  return (
    <>
      {children?.map((c, i) => (
        <StyledA key={i} className="footer_link" href={c?.href}>
          <Typography>{c?.label}</Typography>
        </StyledA>
      ))}
    </>
  );
};

const DmcaBadge = () => {
  return (
    <>
      <a
        href="//www.dmca.com/Protection/Status.aspx?ID=8e4c2732-7ad6-4436-a47e-a4f491c5732c"
        title="DMCA.com Protection Status"
        className="dmca-badge"
      >
        {" "}
        <img
          src="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=8e4c2732-7ad6-4436-a47e-a4f491c5732c"
          alt="DMCA.com Protection Status"
        />
      </a>
      <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
    </>
  );
};

const LastFooter = ({ att }) => {
  const { downTabletBreakpoint } = useMediaBreakpoints();
  const t = useIntl();
  const services = [
    { label: "sitemap", href: PATHS.sitemap },
    { label: "UserAgreement", href: PATHS.termCondition },
    { label: "PrivacyPolicy", href: PATHS.policy },
    { label: "disclaimer", href: PATHS.disclaimers },
  ];

  const serviceComponent = useMemo(
    () => (
      <Row justifyContent={downTabletBreakpoint ? "flex-start" : "flex-end"}>
        {services.map((service, i) => (
          <StyledA key={i} href={service.href}>
            <Typography fontSize={12}>
              {t.formatMessage({ id: service.label })}
            </Typography>
          </StyledA>
        ))}
      </Row>
    ),
    [services]
  );

  return (
    <StyledLastFooter
      container
      className="lastestFooter"
      sx={{
        ...att,
      }}
      spacing={2}
    >
      <Grid item xs={12} md={downTabletBreakpoint ? 12 : 6}>
        <Stack spacing={3}>
          {downTabletBreakpoint && (
            <>
              {serviceComponent}
              <LogoFreshdi />
              <DmcaBadge />
            </>
          )}
          <Row>
            <Typography className="forFarmText">
              <IntlMsg
                id="CopyrightBelongTo"
                values={{ name: "Freshdi.com" }}
              />
            </Typography>
            {!downTabletBreakpoint && <DmcaBadge />}
          </Row>
        </Stack>
      </Grid>
      {!downTabletBreakpoint && (
        <>
          <Grid item xs={12} md={6}>
            {serviceComponent}
          </Grid>
        </>
      )}
    </StyledLastFooter>
  );
};

export default Footer;
