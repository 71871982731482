import { Link, Stack, StackProps, styled } from "@mui/material";
import Row from "./Row";
import { useMemo } from "react";
import { PATHS } from "constants/routes";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { TextButton } from "./Button";
import { useIntl } from "react-intl";
import { XsTextMd } from "./Text";
import { useLanguageContext } from "./LanguageProvider";
import { PackageIcon, TargetIcon, UserGroupIcon } from "Assets/icons";
// import { isSameDomain } from "helpers/url";
// import useNavigate from "hooks/useNavigate";

const HeaderLinks = (props: StackProps & { inrow?: boolean }) => {
  const { language } = useLanguageContext();
  const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();
  // const { onShowSwitchAccount } = useNavigate();
  const intl = useIntl();
  const { inrow } = props;
  const services = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: isMobile ? "find_products" : "products",
        }),
        href: PATHS.products,
        icon: PackageIcon,
      },
      {
        label: intl.formatMessage({
          id: isMobile ? "find_suppliers" : "suppliers",
        }),
        href: PATHS.suppliers,
        icon: UserGroupIcon,
      },
      {
        label: intl.formatMessage({ id: isMobile ? "find_buyers" : "buyers" }),
        href: PATHS.buyers,
        icon: UserGroupIcon,
      },
      {
        label: intl.formatMessage({ id: "RFQsMarket" }),
        href: PATHS.rfqMarket,
        icon: TargetIcon,
      },
    ];
  }, [language, isMobile]);

  if (!isMobile) {
    return (
      <StyledHeaderLinks direction="row" {...props}>
        {services.map((service, i) => {
          return (
            <Link key={i} underline="none" color="#667085" href={service.href}>
              <TextButton className="label">{service.label}</TextButton>
            </Link>
          );
        })}
      </StyledHeaderLinks>
    );
  }

  return (
    <StyledHeaderLinks direction="column" alignItems="flex-start" {...props}>
      {services.map((service, i) => {
        // const mustOnClick = !isSameDomain(service.href);
        return (
          <Link key={i} underline="none" color="#667085" href={service.href}>
            <Stack
              spacing={inrow ? 2 : 0.5}
              alignItems="center"
              justifyContent="center"
              direction={inrow ? "row" : "column"}
              maxWidth={inrow ? "100%" : 74}
              px={inrow ? 0 : 1}
            >
              {!inrow && <service.icon />}
              <XsTextMd className="label" align="center">
                {service.label}
              </XsTextMd>
            </Stack>
          </Link>
        );
      })}
    </StyledHeaderLinks>
  );
};

const StyledHeaderLinks = styled(Stack)(({ theme }) => ({
  ".value": {
    padding: "2px 8px",
    backgroundColor: "#F04438",
    fontSize: 12,
    color: "#fff",
    borderRadius: 16,
  },
  svg: {
    width: 24,
    height: 24,
    color: "#667085",
  },
  ".label": {
    padding: "10px 8px",
    color: "#667085",
    fontSize: 16,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "var(--gray-100)",
    },
  },
  [theme.breakpoints.down("md")]: {
    ".label": {
      fontSize: 14,
    },
  },
}));

export default HeaderLinks;
