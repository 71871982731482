import { Box, Stack, styled } from "@mui/material";

export const StyledInviteRegisterBanner = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  img: {
    aspectRatio: "10.7 / 1",
    width: "100%",
    objectFit: "cover",
  },
  padding: 0,
  [theme.breakpoints.down("md")]: {
    img: {
      aspectRatio: "6 / 1",
    },
  },
}));

export const StyledUpgradePlanToConnectBuyerTips = styled(Box)(({ theme }) => ({
  ".content": {
    padding: "12px 27px",
    backgroundColor: "var(--yellow-25)",
    borderRadius: 8,

    ".text": {
      color: "var(--yellow-500)",
      span: {
        fontWeight: 600,
      },
      ".number": {
        fontSize: 24,
      },
    },
    button: {
      whiteSpace: "nowrap",
      fontWeight: 600,
    },
    ".icon-box": {
      height: 30,
      width: 30,
      backgroundColor: "var(--yellow-100)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      img: {
        height: 15,
        width: 15,
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    ".content": {
      ".text, button": {
        fontSize: 14,
        lineHeight: "20px",
      },
    },
  },
}));

export const StyledTryTrialPlanBanner = styled(Stack)(({ theme }) => ({
  background: "linear-gradient(90deg, #FFF4E5 0%, #FFFFFF 52%, #FFF4E5 100%)",
  padding: "8px 16px",
  button: {
    width: "auto",
    padding: "3px 14px",
    height: 34,
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  [theme.breakpoints.down("md")]: {
    ".title": {
      fontSize: 12,
    },
    button: {
      fontSize: 10,
      padding: "0px 14px",
    },
  },
}));
