import { lazy } from "react";

export const FarmItem = lazy(() => import("./FarmItem"));
export const Farm = lazy(() => import("./Farm"));
export const PageNotFound = lazy(() => import("./PageNotFound"));
export const UserProfile = lazy(() => import("./user-profile"));
export const ResetPassword = lazy(() => import("./reset-password"));
export const QuickRFQ = lazy(() => import("./quick-rfq"));
export const Sitemap = lazy(() => import("./sitemap"));
export const Category = lazy(() => import("./category"));
export const Login = lazy(() => import("./Login"));
export const SendInquiry = lazy(() => import("./send-inquiry"));
export const Register = lazy(() =>
  import("./register" /* webpackChunkName: "register" */)
);
export const ActiveAccount = lazy(() => import("./active-account"));
export const Products = lazy(() => import("./products"));
export const Suppliers = lazy(() => import("./suppliers"));
export const Payment = lazy(() => import("./payment"));
export const ActiveAccountBuyer = lazy(() => import("./active-account-buyer"));
export const Unsubscribe = lazy(() => import("./unsubscribe"));
export const JoinCampaign = lazy(() => import("./join-campaign"));

// export { default as TopProducts } from "./TopProducts";
