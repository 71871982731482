import axios from "axios";
import { API_DOMAIN, TOKEN } from "constants/schemas";
import { getCookie } from "helpers/cookie";

const http = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: TOKEN? `token ${TOKEN}`: '',
    // Cookie: `utm_source=${getCookie('utm_source')}`
  },
  withCredentials: true,
});

export default http;
