import { BoxProps } from "@mui/material";
import Footer from "Components/Footer";
import Header from "Components/Header";
import Main from "Components/Main";
import { TryTrialPlanBanner } from "Components/TipBanners";
import { PLAN_NAMES } from "constants/data";
import useUser from "hooks/useUser";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  mainprops?: BoxProps;
  hideSearch?: boolean;
};

const Layout = (props: Props) => {
  const { children, hideSearch } = props;
  const { currentPlan } = useUser();

  return (
    <>
      {currentPlan.plan_name !== PLAN_NAMES.trial && <TryTrialPlanBanner />}
      <Header {...{ hideSearch }} />
      <Main {...props.mainprops}>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
