import { Link, Stack, Typography, styled } from "@mui/material";
import { IntlMsg, LoadingSkeleton, Product, Row, Swiper } from "Components";
import { SwiperSlide } from "swiper/react";

type Props = {
  isLoading?: boolean;
  itemList: any[];
  itemListLabel?: string;
  hasViewAll?: boolean;
  breakpoints?: any;
};

const ItemList = ({
  isLoading,
  itemList,
  itemListLabel,
  hasViewAll = true,
  breakpoints,
}: Props) => {
  return (
    <StyledItemList spacing={{ xs: 1, md: 2 }}>
      {isLoading && <LoadingSkeleton />}
      {!isLoading && (
        <>
          <Row justifyContent="space-between">
            <Typography className="bold">{itemListLabel}</Typography>

            {hasViewAll && (
              <Link underline="none" className="see_all_text">
                <Typography>
                  <IntlMsg id="view_all" />
                </Typography>
              </Link>
            )}
          </Row>

          <Swiper
            has_panigation
            pagination={undefined}
            breakpoints={breakpoints}
          >
            {(itemList || []).map((product: any, i: number) => (
              <SwiperSlide key={i}>
                <Product {...{ product }} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </StyledItemList>
  );
};

const StyledItemList = styled(Stack)(({ theme }) => ({
  borderRadius: 16,
  [theme.breakpoints.down("md")]: {
    borderRadius: 0,
    // "& .swiper-slide": {
    //   width: "calc(75% / 2 + 8px) !important",
    // },
  },
}));

export default ItemList;
