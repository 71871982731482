import { styled } from "@mui/material";
import { EN_GLOBAL_BG, VI_GLOBAL_BG } from "Assets/images";
import { useLanguageContext } from "Components/LanguageProvider";
import { COUNTRY_CODES } from "constants/data";
import React from "react";

const LeftBanner = () => {
  const { language } = useLanguageContext();
  const StyledDiv = styled("div")({
    width: "calc(50% - 48px)",
    height: "100%",
    background: `url('${
      language === COUNTRY_CODES.vi ? VI_GLOBAL_BG : EN_GLOBAL_BG
    }') center/cover no-repeat`,
  });
  return <StyledDiv />;
};

export default LeftBanner;
