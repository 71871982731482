import {
  Divider,
  Link,
  MenuItem,
  Stack,
  StackProps,
  styled,
} from "@mui/material";
import Row from "./Row";
import IntlMsg from "./IntlMsg";
import { useMemo } from "react";
import { PATHS } from "constants/routes";
import { FiUsers, FiUser } from "react-icons/fi";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { useIntl } from "react-intl";
import Menu from "./Menu";
import useNavigate from "hooks/useNavigate";
import { OpenTabLink, SmTextMd, TextSemibold } from "./Text";
import { useLanguageContext } from "./LanguageProvider";
import { Img } from "./Img";
import { CIRCLE_CROWN_ICON } from "Assets/icons";

const HeaderServices = (props: StackProps) => {
  const { language } = useLanguageContext();
  const { openNewTab } = useNavigate();
  const { downTabletBreakpoint } = useMediaBreakpoints();
  const intl = useIntl();
  const services = useMemo(
    () => [
      {
        label: <IntlMsg id="for_supplier" />,
        icon: <FiUsers />,
        options: [
          {
            label: intl.formatMessage({ id: "request_for_quotations" }),
            href: PATHS.rfqMarket,
          },
          {
            label: intl.formatMessage({ id: "pricing" }),
            href: PATHS.supplierPricing,
          },
          {
            label: <IntlMsg id="SellerCentral" />,
            href: PATHS.supplierCenter,
          },
          {
            label: intl.formatMessage({ id: "FAQs" }),
            href: PATHS.supplierFAQs,
          },
        ],
      },
      {
        label: <IntlMsg id="for_buyer" />,
        icon: <FiUser />,
        options: [
          {
            label: intl.formatMessage({ id: "post_sourcing_request" }),
            href: PATHS.quickRfq,
          },
          {
            label: intl.formatMessage({ id: "pricing" }),
            href: PATHS.buyerPricing,
          },
          {
            label: <IntlMsg id="BuyerCentral" />,
            href: PATHS.buyerCenter,
          },
          {
            label: intl.formatMessage({ id: "FAQs" }),
            href: PATHS.buyerFAQs,
          },
        ],
      },
    ],
    [language]
  );

  const onDirectHref = (href: string) => (href ? openNewTab(href) : null);

  if (!downTabletBreakpoint) {
    return (
      <StyledHeaderServices direction="row" {...props}>
        <OpenTabLink href={PATHS.supplierPricing} className="app-link-btn">
          <TextSemibold className="premium-text">
            <Img src={CIRCLE_CROWN_ICON} alt="icon" />
            {intl.formatMessage({ id: "premium_membership_origin" })}
          </TextSemibold>
        </OpenTabLink>

        {services.map((service, i) => (
          <Row key={i}>
            <Menu
              buttonText={service.label}
              buttonProps={{ className: "app-link-btn" }}
            >
              {service.options.map((o, ii) => (
                // @ts-ignore
                <MenuItem key={ii} onClick={() => onDirectHref(o.href)}>
                  {o.label}
                </MenuItem>
              ))}
            </Menu>
          </Row>
        ))}
      </StyledHeaderServices>
    );
  }

  return (
    <StyledHeaderServices justifyContent="space-between" {...props}>
      {services.map((service, i) => (
        <Row key={i}>
          <Link underline="none" target={"_blank"} color="#344054">
            <Row direction="column" spacing="12px" className="service_link">
              <SmTextMd className="label" align="center">
                {service.label}
              </SmTextMd>
            </Row>
          </Link>
        </Row>
      ))}
    </StyledHeaderServices>
  );
};

const StyledHeaderServices = styled(Stack)({
  ".value": {
    padding: "2px 8px",
    backgroundColor: "#F04438",
    fontSize: 12,
    color: "#fff",
    borderRadius: 16,
  },
  ".app-link-btn": {
    ":hover": {
      textDecoration: "none",
    },
  },
  svg: {
    width: 20,
    height: 20,
    color: "#667085",
  },
  ".premium-text": {
    color: "var(--yellow-500)",
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  ".label": {
    // whiteSpace: "nowrap",
    color: "#667085",
  },
});

export default HeaderServices;
